<template>

  <div>

    <a href="#" v-on:click.prevent="set_show_name(index)" v-bind:title="get_data().users[userID].name" class="img" v-bind:style="get_color_scheme()">

      <img v-if="get_data().users[userID].avatar" v-bind:src="get_data().users[userID].avatar" >
      {{ get_data().users[userID].name[0] }}

    </a>

    <transition name="fade">
      <div v-if="showName === index" v-bind:key="showName" class="fullname">{{ get_data().users[userID].name }}</div>
    </transition>

  </div>

</template>

<script>

export default{

  props: {userID: [Number, String], settings: Object},

  data() {

    var id = new Date().getTime();
    var id2 = false;


    if( typeof this.settings == "object" && typeof this.settings.show == "boolean" && this.settings.show )
      id2 = id;

    return {
      index: id,
      showName: id2
    }

  },

  methods: {

    set_show_name(index) {

      if( typeof this.settings == "object" && typeof this.settings.noClick == "boolean" && this.settings.noClick )
        return false;

      if( this.showName === index )
        this.showName = false
      else
        this.showName = index;

    },

    get_color_scheme() {

      var settings = this.get_data().users[this.userID];

      var r = {'background': settings.background, color: settings.color};

      if( typeof this.settings == "object" && typeof this.settings.noClick == "boolean" && this.settings.noClick )
        r["cursor"] = "default";
      
      return r;

    },

    get_data() {

      if(!this.$store.getters.messages)
        return false;

      return {
        messages: this.$store.getters.messages,
        users: this.$store.getters.users
      }

    }

  }

}

</script>
