var lang = {
  noMessages: "Currently no messages ...",
  submit: "send",
  isWriting: "is writing",
  sendMessage: "Write a message",
  ago: "{date} ago",
  years: "years",
  year: "year",
  months: "months",
  month: "month",
  days: "days",
  day: "day",
  hour: "hour",
  hours: "hours",
  minute: "minutes",
  minutes: "minute",
  second: "second",
  seconds: "seconds",
  lessSeconds: "less seconds",
  thumbsUp: "I agree",
  chatMembers: "chat members",
  clickHere: "click here",
  chatPause: "chat multed",
  hasRead: "read by"
}

export default { lang }
