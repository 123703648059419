<template>

  <div v-if="$store.getters.users">

    <div class="msg-title">{{ $store.getters.l.sendMessage }}</div>
    <update class="update-animation"/>
    <div contenteditable="true" class="msg" ref="msg-txt" spellcheck="false" v-on:keyup="function(e){ change_msg(e) } " v-html="get_msg()" v-bind:key="key">{{ get_msg() }}</div>

    <div>



    <a href="#" class="thumbButton" v-on:click.prevent="add_message_thumbsUp()" v-bind:style="get_color_scheme()">
      <thumbsUp />
    </a>

    <a href="#" class="button" v-on:click.prevent="add_message()" v-bind:style="get_color_scheme()">
      <span class="button-text">{{ $store.getters.l.submit }}</span>
      <profilImage class="tinyImg" :userID="$store.getters.activeUser" :settings="{noClick: true, show: false}"/>
    </a>

    </div>

  </div>

</template>

<script>

import profilImage from '@/components/userpart';
import update from '@/components/update';
import thumbsUp from '@/components/thumbs_up';

export default{

  components: {profilImage, update, thumbsUp},

  data() {

    return  {
      msg: "",
      newMsg: "",
      key: new Date().getTime()
    }
  },
  methods: {

    get_color_scheme() {

      var settings = this.$store.getters.users[this.$store.getters.activeUser];
      return {'border-color': settings.background, 'fill': settings.background};

    },

    add_message_thumbsUp() {
      var oldMsg = this.newMsg;
      this.newMsg = ":thumbsUp";
      this.add_message( {clear: false} );
      this.newMsg = oldMsg;
    },
    add_message( settings ) {

      if(!this.newMsg)
        return false;

      var d = new Date();
      var send = {
        userID: this.$store.getters.activeUser,
        msg: this.newMsg,
        date: d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate()+" "+d.getHours()+":"+d.getMinutes()+":"+d.getSeconds(),
        view: [this.$store.getters.activeUser]
      }

      this.$store.commit("add_message", send);
      this.$store.commit("add_message_callback", send);



      if(typeof settings == "undefined" || typeof settings.clear != "boolean" || settings.clear !== false){
        this.newMsg = "";
        this.msg = "";
        this.key = new Date().getTime();
      }


      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        this.key = new Date().getTime();
      }
      else
        setTimeout(function(){
            this.$refs["msg-txt"].focus();
        }.bind(this), 500)


    },

    get_msg() {
      return this.msg;
    },

    change_msg(e) {

      this.newMsg = e.target.innerHTML;

      this.$store.commit("set_writing", {user: this.$store.getters.activeUser, timeout: new Date().getTime() + 1000 });

      this.$nextTick(function(){

        setTimeout(function(){

          if( typeof this.$store.getters.writing != "object" || this.$store.getters.writing.timeout < new Date().getTime() )
            this.$store.commit("set_writing", false);

        }.bind(this), 2000);

      }.bind(this))
    }
  }

}

</script>
