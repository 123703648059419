<template>

  <transition name="pop">
    <div class="chatPause" :key="getPause()" v-if="getPause()" @click="getClick()">
      <div>
        <span>{{ $store.getters.l.chatPause.toUpperCase() }}</span><br>
        <span>{{ $store.getters.l.clickHere }}</span>
      </div>
    </div>
  </transition>

</template>

<script>

export default{

  methods: {

    getPause(){

      return !this.$store.getters.windowFocus;
    },

    getClick(){
      this.$store.commit("set_window_focus", true);
    }
  }

}

</script>
