import Vue from 'vue'
import App from './App.vue'
import store from './store'

import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'

Vue.config.productionTip = false

Vue.use(vueCustomElement);
Vue.use(store)

App.store = store
Vue.customElement('jj-chatapp', App)
