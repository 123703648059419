<template>

  <div v-if="$store.getters.users">

    <span v-if="$store.getters.writing"><profilImage class="tinyImg" :userID="$store.getters.writing.user" :settings="{noClick: true, show: true}"/> <span class="isWritingNow">{{ get_writing_info() }}</span></span>
    <span v-if="!$store.getters.writing && filter_users()"><span v-for="(user, index) in filter_users()" :key="index"><profilImage class="tinyImg" :userID="index" :settings="{noClick: true, show: true}"/></span></span>
  </div>

</template>

<script>

import profilImage from '@/components/userpart';

export default{

  components: {profilImage},

  methods:{

    get_writing_info() {

      if( typeof this.$store.getters.writing == "object" )
        return " "+this.$store.getters.l.isWriting;
    },

    filter_users() {

      if(typeof this.$store.getters.activeUser == "undefined")
        return false;

      var r = false;

      switch( this.$store.getters.legend ){
        case 'active':
          r = this.users_active();
        break;
        case 'me':
          r = this.users_me();
        break;
        case 'all':
          r = this.$store.getters.users;
        break;
        default:
          if( typeof this.$store.getters.legend == "object" && Object.values( this.$store.getters.legend ).length )
            r = this.users_selected();
        break;
      }

      return r;



    },

    users_me() {

      var r = {};
      var me = this.$store.getters.activeUser;

      r[me] = this.$store.getters.users[me];

      return r;

    },
    users_selected() {

      var r = {};
      var users = this.$store.getters.users;

      Object.values( this.$store.getters.legend ).forEach(function(v){
        r[v] = users[v];
      });

      return r;
    },

    users_active() {

      var me = this.$store.getters.activeUser;
      var r = [];
      var ret = {};



      if(typeof me == "undefined" || typeof this.$store.getters.users == "undefined" || !me ){
        return false;
      }


      if(typeof this.$store.getters.messages == "undefined" || !this.$store.getters.messages || !this.$store.getters.messages.length){
        ret[me] = this.$store.getters.users[me];
        return ret;
      }

      this.$store.getters.messages.forEach(function(user){

        if( user.userID != me && r.indexOf( user.userID ) == -1 )
          r.push(user.userID);

      });

      if(!r.length){
        ret[me] = this.$store.getters.users[me];
        return ret;
      }


      r.forEach(function(user){

        ret[user] = this.$store.getters.users[user];
      }.bind(this));

      ret[me] = this.$store.getters.users[me];


      return ret;

    }
  }
}
</script>
