<template>

  <div v-show="time" v-if="interval">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>

</template>

<script>

  export default{

    data() {

      var interval = false;

      if(typeof this.$store.getters.data.update == "object" && typeof this.$store.getters.data.update.interval == "number")
        interval = this.$store.getters.data.update.interval;

      return{
        time: 0,
        interval: interval
      }
    },
    methods: {

      triggerCallback(){

        if(typeof this.$store.getters.data.update === 'undefined' || typeof this.$store.getters.data.update.callback !== "function")
          return false;

        var my_class = this;

        this.$store.getters.data.update.callback(
          {
            message: function(data){ my_class.addMessage(data); },
            users: function(data){ my_class.changeUsers(data); },
            read: function(data){ my_class.changeRead(data); }
        } )

      },

      changeRead(data){

        var messages = this.$store.getters.messages;

        Object.keys(data).forEach(function(key){
          messages[key].view = data[key];
        });

        this.$store.commit("set_messages", messages);


      },

      changeUsers(data) {

        this.$store.commit("set_users", data);

      },

      addMessage(data) {

        if(typeof data != "object")
          return false;

         var my_class = this;

        data.forEach(function(v){

          my_class.$store.commit("add_message", v);

        });

      },

      getTimer() {

        if( typeof this.$store.getters.data.update != "object" )
          return false;

        var i = (this.interval);

        var focus = this.checkDocumentFocus();

        if( this.time == i ){

          setTimeout( function(){
            this.time = 0;
          }.bind(this), 2000);

          if(focus)
            this.triggerCallback();
        }

        setTimeout( function(){

          if(focus){

            this.time += i;

            if( this.time > this.interval )
              this.time = 0;
          }

          this.getTimer()



         }.bind(this), i);

      },

      checkDocumentFocus(){

        if( !document.hasFocus() ){

          this.$store.commit("set_window_focus", false);
          return false;
        }

        this.$store.commit("set_window_focus", true);
        return true;

      }

    },

    mounted() {
      this.getTimer()
    }
  }

</script>
