var lang = {
  noMessages: "Bislang liegen keine Nachrichten vor...",
  submit: "senden",
  isWriting: "schreibt gerade",
  sendMessage: "Nachricht verfassen",
  ago: "vor {date}",
  years: "Jahren",
  year: "Jahr",
  months: "Monaten",
  month: "Monat",
  days: "Tagen",
  day: "Tag",
  hour: "Stunde",
  hours: "Stunden",
  minute: "Minute",
  minutes: "Minuten",
  second: "Sekunde",
  seconds: "Sekunden",
  lessSeconds: "wenigen Augenblicken",
  thumbsUp: "Ich stimme zu",
  chatMembers: "Chat-Teilnehmer",
  clickHere: "Hier klicken",
  chatPause: "Chat pausiert",
  hasRead: "wurde gelesen"
}

export default { lang }
