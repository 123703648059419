<template>

  <div id="jj_chat_area">

      <translations class="translation_area"/>
      <isWriting class="writing_area" v-if="$store.getters.legend"/>
      <messages class="msg_area"/>
      <insert class="insert_area"/>

      <pause class="pause_area"/>
  </div>

</template>

<script>

import messages from '@/components/messages';
import translations from '@/components/translations';
import insert from '@/components/insert';
import isWriting from '@/components/writing';
import pause from '@/components/pause';

export default{

  components: {messages, insert, isWriting, pause, translations},

  methods: {
    getStartChat () {
      this.$store.commit("set_users", this.$store.getters.data.users);
      this.$store.commit("set_messages", this.$store.getters.data.messages);

      if( typeof this.$store.getters.data.activeUser != "undefined" )
        this.$store.commit("set_active_user", this.$store.getters.data.activeUser);

      if( typeof this.$store.getters.data.legend != "undefined" )
        this.$store.commit("set_legend", this.$store.getters.data.legend);
    },
    getLoadChat () {
      if (typeof this.$store.getters.data === 'object' && Object.keys(this.$store.getters.data).length) {
        this.getStartChat()
        return false
      }
      setTimeout (function() {
        this.getLoadChat()
      }.bind(this), 1000)
    }
  },

  mounted() {

    this.getStartChat()


  },



}

</script>

<style lang="sass">
  @import "src/style/style.sass"
</style>
