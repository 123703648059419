<template>
  <div>
    <a v-if="!open" href="#" class="current" @click.prevent="open = true">{{ $store.getters.language }}</a>
    <div v-if="open">
    <span  v-for="lang in $store.getters.translation" :key="lang">
      <a href="#" @click.prevent="setLang(lang)">{{ lang }}</a>
    </span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      open: false
    }
  },
  methods: {
    setLang (lang) {
      this.$store.commit('setLanguage', lang)
      this.open = false
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
