<template>

  <div v-if="getView()" class="viewImgContainer" v-bind:title="$store.getters.l.hasRead">
    <div class="viewImg"><span class="hasRead">{{ $store.getters.l.hasRead }}</span></div>
      <userpart v-for="userID in getView()" :key="userID" :userID="userID"  class="viewImg" :settings="{noClick: true, show: false}"/>

      <div class="clearfix" />

  </div>

</template>

<script>
import userpart from '@/components/userpart';

export default{

  components: {userpart},

  props: {
    view: Number,
  },

  methods: {
    getView(){

      var view = this.$store.getters.messages[this.view].view;

      if( view.indexOf(this.$store.getters.activeUser) == -1 && view.indexOf(this.$store.getters.activeUser.toString()) == -1 )
        view.push( this.$store.getters.activeUser );

      return view;

    },
    getUser(){
      return this.$store.getters.users;
    }
  }
}

</script>
