import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import de from '@/store/de_DE.js'
import en from '@/store/en_EN.js'

export default function makeStore() {

  return new Vuex.Store({
  state: {
    data: false,
    windowFocus: true,
    lastChange: new Date().getTime(),
    users: false,
    messages: false,
    isWriting: false,
    activeUser: 1,
    legend: "all",
    language: "de",

    translation: {
      de: de.lang,
      en: en.lang
    }
  },
  mutations: {
    setLanguage(state, lang) { state.language = lang },
    setData(state, data) { state.data = data },
    set_window_focus(state, data){ state.windowFocus = data },
    set_legend(state, data){ state.legend = data },
    set_writing(state, data){ state.isWriting = data },
    set_users(state, user){ state.users = user },
    set_active_user(state, userID){ state.activeUser = userID },
    set_messages(state, messages){ state.messages = messages },
    add_message(state, message){ state.messages.push(message); state.lastChange = new Date().getTime(); },
    add_message_callback(state, message){

      if(typeof state.data == "undefined" || typeof state.data.onSubmit != "function" )
        return false;

      state.data.onSubmit(message);

    }

  },
  actions: {
  },
  modules: {
  },
  getters: {
    language(state) { return state.language },
    translation(state) { return Object.keys(state.translation) },
    windowFocus(state) { return state.windowFocus },
    writing(state) { return state.isWriting },
    users(state) { return state.users },
    activeUser(state) { return state.activeUser },
    messages(state) { return state.messages },
    lastChange(state) { return state.lastChange },
    legend(state){ return state.legend },
    l(state) { return state.translation[state.language] },
    data(state) { return state.data }
  }
})
}
