<template>
  <div>
    <chat v-if="$store.getters.data" />
  </div>
</template>

<script>
import chat from '@/components/chat'

export default{
  props: {
    id: [String, Number]
  },
  components: {chat},
  methods: {
  },
  mounted () {
    if (typeof window[this.id].language === 'string') {
      this.$store.commit('setLanguage', window[this.id].language)
    }
    this.$store.commit('setData', window[this.id])
    delete window[this.id]
  }
}

</script>
