<template>

  <div v-bind:key="update_key" v-on:click.prevent="change_view()">
    {{ get_time() }}

  </div>

</template>

<script>

export default{
  props: {time: [String, Number]},

  data() {

    return {
      update_key: false,
      beauty_date: false,
      timer: false,
      view: "relative"
    }
  },

  methods:{
    change_view() {

      if(this.view == "relative"){
        this.view = "absolute";
        clearTimeout(this.timer);
      }
      else{
        this.view = "relative";
        this.get_update_key();
      }

    },
    get_time(){

      var time ;

      if(this.view == "relative"){
        time = this.get_calc_time();

      }
      else{
        time = this.beauty_date+" Uhr";

      }

      return time;
    },

    get_calc_time(){

      var ymd_his = this.time.split(" ");
      var his = ymd_his[1].split(":");
      var ymd = ymd_his[0].split("-");

      var time = new Date( ymd[0], ymd[1] - 1, ymd[2], his[0], his[1], his[2], 0 );

      this.beauty_date = this.get_nulls_before((ymd[2]))+"."+this.get_nulls_before(ymd[1])+"."+ymd[0]+", "+this.get_nulls_before(his[0])+":"+this.get_nulls_before(his[1]);

      return this.$store.getters.l.ago.replace("{date}", this.timeSince(time));

    },

    get_nulls_before(time) {

      time = time * 1;

      if(time < 10)
        return 0+""+time;

      return time;
    },

    timeSince(date) {

      var seconds = Math.floor((new Date() - date) / 1000);

      if(!seconds)
        return " "+this.$store.getters.l.lessSeconds;

      var interval = Math.floor(seconds / 31536000);

      if(interval == 1)
        return interval + " "+this.$store.getters.l.year;

      if (interval > 1)
        return interval + " "+this.$store.getters.l.years;

      interval = Math.floor(seconds / 2592000);

      if(interval == 1)
        return interval + " "+this.$store.getters.l.month;

      if (interval > 1)
        return interval + " "+this.$store.getters.l.months;

      interval = Math.floor(seconds / 86400);

      if(interval == 1)
        return interval + " "+this.$store.getters.l.day;

      if (interval >= 1)
        return interval + " "+this.$store.getters.l.days;

      interval = Math.floor(seconds / 3600);

      if(interval == 1)
        return interval + " "+this.$store.getters.l.hour;

      if (interval > 1)
        return interval + " "+this.$store.getters.l.hours;

      interval = Math.floor(seconds / 60);

      if (interval == 1)
        return interval + " "+this.$store.getters.l.minute;

      if (interval > 1)
        return interval + " "+this.$store.getters.l.minutes;

      if( Math.floor(seconds) == 1 )
        return seconds + " "+this.$store.getters.l.second;

      return Math.floor(seconds) + " "+this.$store.getters.l.seconds;
    },

    get_update_key() {

      this.update_key = new Date().getTime();

      this.timer = setTimeout(function(){

        this.get_update_key();

      }.bind(this), 30000);

    }
  },



  mounted() {

    this.get_update_key();

  }
}

</script>
