<template>


  <div class="container" ref="msg">

    <div v-if="!get_data() || !get_data().messages || !get_data().messages.length" class="noMessages">
      {{ $store.getters.l.noMessages }}
    </div>


    <div class="showMessages" v-else>

      <transition-group name="messagesAnimation" tag="ul"  >
        <li v-for="(msg, index) in get_data().messages" v-bind:key="index"  class="msg-container" v-bind:class="get_class(msg)">

          <userpart :userID="msg.userID"/>

          <thumbsUp class="msg" v-if="msg.msg == ':thumbsUp'" v-bind:style="get_color_scheme_icon(msg)">{{ $store.getters.l.thumbsUp }}</thumbsUp>
          <div v-else class="msg" v-bind:style="get_color_scheme(msg)" v-html="msg.msg"></div>


          <div class="date"><timeview :time="msg.date" /></div>

          <messageView :view="index" />

        </li>
      </transition-group>

    </div>


  </div>

</template>

<script>

import userpart from '@/components/userpart';
import timeview from '@/components/time';
import thumbsUp from '@/components/thumbs_up';
import messageView from '@/components/messageView';

export default{

  components: {userpart, timeview, thumbsUp, messageView},


  methods: {

    get_class(msg) {

      if(this.$store.getters.activeUser == msg.userID)
        return {"me": true};

      return "";
    },

    get_color_scheme(msg) {

      var settings = this.get_data().users[msg.userID];

      return {'background': settings.background, color: settings.color, 'fill': settings.color};

    },

    get_color_scheme_icon(msg) {

      var settings = this.get_data().users[msg.userID];

      return {'background': settings.color, color: settings.background, 'fill': settings.background};

    },

    get_data() {

      if(!this.$store.getters.messages)
        return false;



      return {
        messages: this.$store.getters.messages,
        users: this.$store.getters.users
      }


    },

    scrollDown() {

      var elem = this.$refs.msg;
      elem.scrollTop = elem.clientHeight * this.$store.getters.messages.length;
    }

  },

  watch: {
    '$store.getters.messages': function(){ setTimeout(function(){ this.scrollDown(); }.bind(this), 0); }
  },

  mouted() {

  }

}

</script>
